body,
#root {
  margin: 0%;
  padding: 0%;
  font-family: "Lato", sans-serif;
  background-color: #004a6e;
}

#root {
  position: relative;
  background-color: #004a6e;
  min-height: 100vh;
  overflow-x: hidden;
}

.major-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000136;
  justify-content: space-between;
}

.major-nav div {
  margin: 0%;
  padding: 0%;
  text-align: center;
  display: flex;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  display: flex;
  width: 10vw;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  height: 4vh;
}

.nav-link:hover {
  background-color: #ffffff;
  color: #000000;
}

.active {
  color: #ff0000;
}

.intro {
  background-color: #141414;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2%;
}

.intro img {
  width: 400px;
  height: 400px;
  border: 5px solid #ffffff;
}

.intro p {
  opacity: 0;
  letter-spacing: 0.15em;
  animation-name: fadeIn;
  animation-delay: 2s;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  font-size: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.intro p:hover {
  opacity: 1;
  animation-duration: 0s;
}

.about {
  color: #ffffff;
  padding: 3%;
}

.about h1 {
  font-size: 47px;
}

.about p,
.about ul li {
  font-size: 20px;
}

.about-imgs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5%;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #000136;
  color: #ffffff;
  width: 100vw;
  /* position: absolute;
  bottom: 0px; */
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 20vw;
}

.social-links img {
  width: 25px;
  height: 25px;
  border: 1px solid #ffffff;
}
.social-links img:hover {
  cursor: pointer;
  opacity: 65%;
}

::-webkit-scrollbar {
  width: 0.5vw;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #000000;
}

::-webkit-scrollbar-thumb:hover {
  background: #272727;
}

.trainers-intro {
  padding: 2%;
  color: #ffffff;
}

.trainers-intro h1 {
  font-size: 47px;
}

.trainers-intro p {
  font-size: 20px;
}

.table-image img {
  width: 200px;
  border: 3px solid #ffffff;
}

.trainers-table {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
}

.trainers-table table {
  width: 65vw;
  border-spacing: 3vw;
}

.table-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trainers-table tr {
  opacity: 0;
}

#trainer-row1 {
  -webkit-animation-name: FadeEntry;
  animation-name: FadeEntry;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

#trainer-row2 {
  animation-name: FadeEntry;
  animation-delay: 1.3s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#trainer-row3 {
  animation-name: FadeEntry;
  animation-delay: 2.1s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#trainer-row4 {
  animation-name: FadeEntry;
  animation-delay: 2.9s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#trainer-row5 {
  animation-name: FadeEntry;
  animation-delay: 3.7s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.contact-intro {
  color: #ffffff;
  padding: 2%;
}

.contact-intro h1 {
  font-size: 47px;
}

.contact-intro p {
  font-size: 20px;
}

.contact-details {
  padding: 2%;
  height: 80vh;
}

.contact-details-link {
  text-decoration: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35vw;
  margin: 2%;
  padding: 1%;
  border: 2px solid #ffffff;
}

.contact-details-link span {
  font-size: 18px;
}

.contact-details-link img {
  width: 100px;
  height: 100px;
}

.contact-details-link:hover {
  cursor: pointer;
  opacity: 50%;
}

.buyMembership-intro {
  padding: 2%;
}

.buyMembership-intro h1 {
  color: #ffffff;
  font-size: 47px;
}

.buyMembership-intro p {
  font-size: 20px;
  color: #ffffff;
}

.instruction {
  font-weight: bolder;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  /* height: 95vh; */
}

.form form {
  background-color: #141414;
  padding: 2%;
  width: 20vw;
}

.form-input {
  display: flex;
  flex-direction: column;
  padding: 2%;
  margin: 2%;
}

.form-input label {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 2.75%;
}

.form-input input,
.form-input select {
  height: 4vh;
  width: 19vw;
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffff00;
  font-size: 18px;
}

.form-input select option {
  background-color: #141414;
}

.form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-submit button {
  background-color: #18ff78;
  width: 10vw;
  height: 5vh;
  color: #000000;
}

.form-submit button:hover {
  background-color: #ffffff;
  color: #000000;
}

.form-error-msg {
  color: #ff0000;
  margin-top: 0%;
  margin-bottom: 1%;
  display: none;
}

.modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 50vh;
  background-color: #004a6e;
  flex-direction: column;
  justify-content: space-evenly;
}

.modal-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.modal-text h1 {
  display: none;
  text-align: center;
  color: #18ff78;
  font-size: 2em;
}

#modal-para-one {
  display: none;
  text-align: center;
  color: #f3f3f3;
  font-size: 1.5em;
}

#modal-loading {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #141414; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#modal-para-two {
  color: #ffffff;
  text-align: center;
}

#modal-button {
  background-color: #00ffff;
  color: #000000;
  display: none;
}

#modal-para-one a:visited {
  color: #ffffff;
}

#modal-send-qr-again {
  display: none;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #ffffff;
}

#modal-send-qr-again:hover {
  opacity: 80%;
  cursor: pointer;
}

@keyframes FadeEntry {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mobile-major-nav {
  display: flex;
  flex-direction: column;
  background-color: #000136;
  align-items: center;
  justify-content: center;
  display: none;
}

.hamburger {
  display: none;
}

.mobile-major-nav a {
  color: #ffffff;
  text-decoration: none;
  margin: 0.5% 0%;
}

@media screen and (max-width: 1600px) {
  .nav-link {
    width: 12vw;
  }

  .form form {
    width: 25vw;
  }

  .form-input input,
  .form-input select {
    width: 24vw;
  }

  .form-input label {
    font-size: 20px;
  }
}

@media screen and (max-width: 1400px) {
  .nav-link {
    font-size: 18px;
  }
  .intro img {
    width: 300px;
    height: 300px;
  }
  .about-imgs {
    flex-direction: column;
    height: 1600px;
  }
  .about-imgs img {
    width: 400px;
  }
  /* .form {
    height: 95vh;
  } */

  .form form {
    width: 40vw;
  }

  .form-input input,
  .form-input select {
    width: 38vw;
  }
}

@media screen and (max-width: 1000px) {
  .major-nav {
    /* display: none; */
  }

  .intro img {
    width: 250px;
    height: 250px;
  }

  .about-imgs {
    flex-direction: column;
    height: 1200px;
  }

  .form {
    background-color: #141414;
    width: 100vw;
  }

  .form form {
    width: 50vw;
  }

  .form-input input,
  .form-input select {
    width: 49vw;
  }

  .form-error-msg {
    font-size: 18px;
  }

  .form-submit button {
    width: 30vw;
    font-size: 22px;
  }
}

@media screen and (max-width: 800px) {
  .contact-details-link {
    width: 50vw;
  }
}

@media screen and (max-width: 700px) {
  .contact-details-link {
    width: 80vw;
  }

  .form form {
    width: 100vw;
  }

  .form-input input,
  .form-input select {
    width: 80vw;
  }
}

@media screen and (max-width: 600px) {
  .intro {
    flex-direction: column;
  }
  .intro p {
    font-size: 16px;
    text-align: center;
  }

  .about h1,
  .trainers-intro h1,
  .buyMembership-intro h1 {
    font-size: 43px;
  }
  .about p,
  .about ul li,
  .trainers-intro p,
  .buyMembership-intro p {
    font-size: 16px;
  }

  .table-image img {
    width: 150px;
  }
  .trainer-info {
    font-size: 16px;
  }

  .trainers-table table {
    width: 100vw;
  }

  .form-input input,
  .form-input submit {
    height: 6vh;
  }

  .hamburger {
    display: flex;
  }

  .major-nav {
    display: none;
  }

  .hamburger:hover {
    opacity: 0.8;
  }

  .hamburger:active {
    background-color: #ffffff;
  }
}

@media screen and (max-width: 400px) {
  .about-imgs img {
    width: 250px;
  }

  .about-imgs {
    height: 900px;
  }

  .table-image img {
    width: 100px;
  }

  .table-image p {
    font-size: 16px;
  }

  .trainer-info {
    font-size: 12px;
  }

  .footer {
    flex-direction: column;
  }

  .social-links {
    justify-content: space-around;
    width: 70vw;
  }
}
